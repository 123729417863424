<template>
  <div>
    <el-dialog :append-to-body="true" :visible.sync="dialog" :title="isAdd ? '新增':'修改'" :close-on-click-modal="false" @closed="cancel" width="600px">
      <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="100px" v-if="form" :disabled="disabled">
        <el-form-item label="来源" prop="name">
          <el-input v-model="form.name" style="width:350px" :maxlength="20" />
        </el-form-item>
        <el-form-item label="是否启用" prop="enabled">
          <el-switch v-model="form.enabled" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="text" @click="dialog = false">取消</el-button>
        <el-button :loading="loading" type="primary" @click="doSave">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { add, edit } from "@/api/orderSource";
export default {
  props: {
    isAdd: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      dialog: false,
      disabled: false,
      form: {
        id: null,
        name: null,
        enabled: true,
      },
      rules: {
        name: [{ required: "true", message: "来源为必填项" }],
      },
    };
  },
  methods: {
    cancel() {
      this.form = null;
    },

    doSave() {
      if (this.isAdd) {
        this.doAdd();
      } else this.doEdit();
    },

    doAdd() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loading = true;
          add(this.form)
            .then((res) => {
              this.dialog = false;
              this.$notify({
                title: "保存成功",
                type: "success",
                duration: 2500,
              });
              this.loading = false;
              this.$parent.init();
            })
            .catch((err) => {
              this.loading = false;
              console.log(err.response.data.message);
            });
        }
      });
    },

    doEdit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loading = true;
          edit(this.form)
            .then((res) => {
              this.dialog = false;
              this.$notify({
                title: "修改成功",
                type: "success",
                duration: 2500,
              });
              this.loading = false;
              this.$parent.init();
            })
            .catch((err) => {
              this.loading = false;
              console.log(err.response.data.message);
            });
        }
      });
    },
    restForm(form) {
      this.dialog = true;
      this.disabled = false;
      this.form = form || {
        name: null,
        enabled: true,
      };
    },
  },
};
</script>

